h3 {
  font-family: 'Anton', sans-serif;
}

html {
  scroll-behavior: smooth;
}

.head-text {
  position: relative;
}

.rounded-div {
  position: relative; /* Add position relative to the rounded div */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px; /* Adjust the value to control the level of rounding */
  background-color: #f0f0f0; /* Set the background color of the div */
  padding: 20px; /* Add padding to the content inside the div */
  min-height: 400px;
}

.icon-container {
  width: 100px; /* Adjust the width of the icon container */
  height: 100px; /* Adjust the height of the icon container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-on-image {
  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

@keyframes reveal {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 25%;
    /* Adjust this value to control the progress */
  }
}
